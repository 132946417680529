<template>
  <modal :show="show" max-width="lg" @close="close">
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8 h-8 text-gray-600"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    </template>

    <template #title> Update your billing details </template>

    <div class="px-4 py-5">
      <text-input
        ref="companyName"
        label="Company Name"
        name="name"
        :required="true"
        :form="form"
        help="Name that will appear on invoices"
      />
      <text-input
        label="Email"
        name="email"
        native-type="email"
        :required="true"
        :form="form"
        help="Where invoices will be sent"
      />
      <v-button
        :loading="form.busy || loading"
        :disabled="form.busy || loading ? true : null"
        class="mt-6 block w-full bg-primary-600 hover:bg-primary-700"
        :arrow="true"
        @click="saveDetails"
      >
        Go to checkout
      </v-button>
    </div>
  </modal>
</template>

<script>
import { computed } from "vue"
import TextInput from "../../forms/TextInput.vue"
import VButton from "~/components/global/VButton.vue"

export default {
  components: { VButton, TextInput },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    plan: {
      type: String,
      default: "pro",
    },
    yearly: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const authStore = useAuthStore()
    return {
      user: computed(() => authStore.user),
    }
  },

  data: () => ({
    form: useForm({
      name: "",
      email: "",
    }),
    loading: false,
  }),

  computed: {},

  watch: {
    user() {
      this.updateUser()
    },
    show() {
      // Wait for modal to open and focus on first field
      setTimeout(() => {
        if (this.$refs.companyName) {
          this.$refs.companyName.$el.querySelector("input").focus()
        }
      }, 300)

      this.loading = false
    },
  },

  mounted() {
    this.updateUser()
  },

  methods: {
    updateUser() {
      if (this.user) {
        this.form.name = this.user.name
        this.form.email = this.user.email
      }
    },
    saveDetails() {
      if (this.form.busy) return
      this.form.put("subscription/update-customer-details").then(() => {
        this.loading = true
        opnFetch(
          "/subscription/new/" +
            this.plan +
            "/" +
            (!this.yearly ? "monthly" : "yearly") +
            "/checkout/with-trial"
        )
          .then((data) => {
            window.location = data.checkout_url
          })
          .catch((error) => {
            useAlert().error(error.data.message)
          })
          .finally(() => {
            this.loading = false
            this.close()
          })
      })
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>
